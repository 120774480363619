import { apiPrefix } from '~/api';
/**
 * Call "User Exists" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/users__id__exists/
 */
export async function existsUser($http, id) {
    const { exists } = await $http.$get(`${apiPrefix}/users/${id}/exists`);
    return exists;
}
/**
 * Call "Get Current User Data" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/user--get/
 */
export function getCurrentUser($http) {
    return $http.$get(`${apiPrefix}/user`);
}
/**
 * Call "Get User List" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/users/
 */
export function getUserList($http, name, area, code) {
    const searchParams = new URLSearchParams();
    if (name)
        searchParams.append('name', name);
    if (area)
        searchParams.append('area', `${area}`);
    if (code)
        searchParams.append('code', `${code}`);
    return $http.$get(`${apiPrefix}/users`, { searchParams });
}
/**
 * Call "Get User Information" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/users__id/
 */
export function getUserInfo($http, id) {
    return $http.$get(`${apiPrefix}/users/${id}`);
}
/**
 * Call "Get Clear Status" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/users__id__clear/
 */
export function getClearStatus($http, userId, playStyle) {
    return $http.$get(`${apiPrefix}/users/${userId}/clear?style=${playStyle}`);
}
/**
 * Call "Get Score Status" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/users__id__score/
 */
export function getScoreStatus($http, userId, playStyle) {
    return $http.$get(`${apiPrefix}/users/${userId}/score?style=${playStyle}`);
}
/**
 * Call "Get Groove Radar" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/users__id__radar__style/
 */
export function getGrooveRadar($http, userId, playStyle) {
    return $http.$get(`${apiPrefix}/users/${userId}/radar/${playStyle}`);
}
/**
 * Call "Post User Information" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/user--post/
 */
export function postUserInfo($http, user) {
    return $http.$post(`${apiPrefix}/user`, user);
}
