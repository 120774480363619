import { computed, defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ErrorPage',
    layout: 'empty',
    props: {
        error: { type: Object, default: null },
    },
    setup(props) {
        // Computed
        const statusCode = computed(() => { var _a, _b; return (_b = (_a = props.error) === null || _a === void 0 ? void 0 : _a.statusCode) !== null && _b !== void 0 ? _b : 500; });
        const path = computed(() => { var _a, _b; return (_b = (_a = props.error) === null || _a === void 0 ? void 0 : _a.path) !== null && _b !== void 0 ? _b : ''; });
        const message = computed(() => { var _a, _b; return (_b = (_a = props.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : ''; });
        return { statusCode, path, message };
    },
});
