export { 
/* istanbul ignore next */
difficultyMap, 
/* istanbul ignore next */
getNameIndex, 
/* istanbul ignore next */
isValidId, 
/* istanbul ignore next */
nameIndexMap, 
/* istanbul ignore next */
playStyleMap, 
/* istanbul ignore next */
seriesSet, } from './db';
/**
 * Returns whether the song has been deleted on the e-amusement GATE site.
 * @param songId Song Id
 * @param series e-amusement GATE version
 * @returns true if deleted at provided version.
 */
export function isDeletedOnGate(songId, series = 'DanceDanceRevolution A20') {
    return (series !== 'DanceDanceRevolution A' &&
        (deletedOnA.includes(songId) ||
            (series === 'DanceDanceRevolution A3' &&
                deletedOnA20Plus.includes(songId))));
}
/** Deleted song id on DanceDanceRevolution A. */
const deletedOnA = [
    '1IbDP96O99Ob10PoPdP98IDqibqo9lqI',
    'qoIDP9I968q8do0d6d6lQDIi8biPIoi1',
    '9Do1l69IiP98IO91bd86oIIoQqQI9QPb',
    'O6DPoIoQibQO1iD6I0DDDbbddDOi9QDq',
    '19l9bOoI8Dib69D0DodDo1d8i8IDb88q',
    '1bD9Pi1bdPdOO111iPlbQl0iI6qdoPIo',
    '6b8lbl11Ii81bIiq1D9do811D986iDOq',
    '81II88dobiP6Q1iq6dDOPqPQQ1dO0Qq9',
    'oqQIlDl811qQI01O8dDdl699OI9IbIlO',
    '8d6DoOIdD61DOIQI1I0869qO99iii68Q',
    'Q6Do1o1bP60Q1O00Dbbqd96Oq9dQd6i6',
    '6i86dIDQQ8DQ60Piq1bqO0i08qI0ibPI',
    'PPDQloDPD9l1dOd6DOi8Oq8IIob9IPO1',
    'odObQ86Qd1qOD9oiP6OdObdP8lqiiD8b',
    'l88PDoOI6b1o1ddI9bDDPil6QdDbPobI',
    'b1b966I1PQ6Qo6bdI6i9i1D1bqdd188i',
    'O06bdqiPIb6i1OdqoQo0qQ0DoO1Od08O',
    '6qOIDDb9libIDlQI80l88P0io68Qq0O8',
    'l9Di1l6I68ilPDlPlO6qO61l11P008OQ',
    'qPPbDIqol6d06iD6dIdid6QQ9oPq9IDo', // Strobe♡Girl
];
/** Deleted song id on DanceDanceRevolution A20 PLUS. */
const deletedOnA20Plus = [
    '19id1DO6q9Pb1681db61D8D8oQi9dlb6',
    'b9OboQl6d9PqQddIdQDobP0d8o6dOqob',
    'P986dlQP0DO0106oq0P81Qi0QQDQ666O',
    'o0DP6qqql9D1bDbQPOQiP8iIq81qI8O9',
    '880I00ODqPD1OQQPOP0Pd19Qq1qiloDP',
    'PbIq9b1I161P0iD18qQiOO9qIoodbDIP',
    '666q06q6I01loQOq0qd98IIOObIDOd8q',
    'l8Q1Od0d0IQOQl61l8lIlPiI80P10OQb',
    'ol8oiPo8iod9bDPPD1qdlbPd1ddqP6oP',
    'OPi8oobIDid6q0P18QdD11D6O1I6Ioqo',
    'qDDlD0blPO68IQiOQ8i1qPDd8IOPIIO6',
    '9IliQ1O0dOQPiObPDDDblDO6oliDodlb',
    'IlQodD9Dbld8QiOql68bPPQbd6bll6i1',
    'dib16I1b0o9OdOd1O90dO1Q6iIO9PQo9',
    '8OoDQb16lP0i96qiDQqo90Q6bOP1o89D',
    'ddql809Qo8q68918lIDOPDQ6q1liD9b6',
    '9D69dQo1dQqPD6l9ObiI0b0i8d9ddool',
    'Pbbidbl9l8IqddQOq0069oQ6Oi1DQdP1',
    'idiOI1Qb9D0dQI6IOlob8QbOqdDOoObP',
    'ID9Dqo9QQQIP9ObD0lbbi6O6PiD18oD0',
    'bPQDblO8Do0Oo9O0PP0b8PO1PblDioDP',
    'o1Q8Ol8Dol9b0dllD6P0iPQbIoP666Db',
    '91qD6DbDqi96qbIO66oboliPD8IPP6io',
    'qOlDPoiqibIOqod69dPilbiqD6qdO1qQ',
    'dOblQOoDb96l00dqPlIb9DQl86q9PboI',
    'od66Qb16lI019I06lllII811I9ol6l0i',
    'PqOilI0ql6QDID6oo0Qb9iDo1doqQqPQ',
    'blDbDqdo1D0odlQd9biIoio8ioQPb80i',
    '00q86iQQIIiOlqi6Doqi6b9PiOodo10O',
    '6DP8POdO1PiP0D8il08Po6iD8oI9Pidl',
    '9OP0iqDD8PDIb8lblD0ol09oP1I1d9PO',
    'dll6Dq0blbl1iPbIIiQ61QIoo00doqiI',
    '00Qbd9qoI681Q0biQOIiI08bI91i080l',
    'qPi8qIqO1lQd08b01PiPlql9O1dOPDPq',
    '1i8d1DodloQiQ8doOlloDIOO9PI6OIoO',
    'io9b89PD18ibIO8PIqb1QODliqDdqiIO',
    'Ol01P9IIoDIOldo8IQI0l6bd9lb9q0oo',
    '80Q1idDo6O6Db0106b0q6qbOP1P8QQb0',
    'QPd01OQqbOIiDoO1dbdo1IIbb60bqPdl',
    'd0DDd1dP6llPbioqPdiQ0IDd918bDqI8',
    '666ObIO9bO8D8q8q08I1OQo96DdIbbiO',
    'D16P668Q8loqIoDo1IdoIlIloiIoIDll',
    'b9QiIQ18b6o1bqPdqI6lI1OIqboPIDI9',
    'q6661PIbbb1O80OQQ11iD6bP1l6bio0Q',
    'DQilQP810dq8D9i11q6Oq0ooDdQQO0lI',
    '6bo6ID6l11qd6lolilI6o6q8I6ddo88i',
    'd0l89dI9d6Di11DQ9P8D1Pl1d0Db81D9',
    'b6qOqD9bOQO1O0q8000D6dIdqb80li1b',
    '6loIiOd8PP90dPOq16Q6PdPPO0DQDOPP',
    '91DOoD99iIq9oIdOi9QqDQ0qlQPQPOii',
    'odPd96PliIo9OIO8q8iD8Qd6QoId6o1d',
    'l6Pd9dQdoq1qI9iOOqdbDQI0qiQioP60',
    'O91QbQb0QdObIO6d8lo8lq00ibOQP9PQ',
    'OdOP8o0dQdo0DPiio6dI8QId869D9oqd',
    'o11OO8oodDODD0oolD6ql6DiODPQboOo',
    '6q6Oil608iDQlOD86d1qiDPo8Dd8IP16',
    'bIb6Q6DD9iP1d61dbOqdi6IQPllOb1IP',
    'iDiiO18PPl6iDlDlddi9ildIqO60bQdo',
    'Iblb8l6qPOQD8do891b1O0Pd9q60b6oD',
    'ilD1Qb1IqqDP1bo1i66q688q6doPq6Qb',
    'q0IObiQdI9o918O0DbPlldqd01liQ8Ql',
    'dO01ddIq0bq9019olIDoD1IIPIb9DQ9D',
    'b0DliOIqlP9lldO9qQo0986QIo9io96d',
    'oiqql6iPq8Oq0QIqlqb1DOOO8ioPo8b9',
    'DQqi68IP1qbDiQ9li6PI1Q9Iddd6o9QQ',
    '9O0b1DddI6OiOD6PQbboPbdd8Pi1iPlb',
    '199ODd8l0Qld9i6DdqOdb1ll66D1I1Q9',
    'dll9D90dq1O09oObO66Pl8l9I9l0PbPP',
    'qbbOOO1QibO1861bqQII9lqlPiIoqb98',
    'I90bQ81P1blOPIdd9PPl6I9D8DQ1dIob',
    '88o8Oq69ldilblP10DI0qqb6b8I0DDi9',
    'q6oOPqQPlOQoooq888bPI1OPDlqDIQQD',
    'i1DqPb01I6I1dP8qoO1qiIPDlOD9D9oQ',
    'DQilQP810dq8D9i11q6Oq0ooDdQQO0lI', // HARD DANCE
];
