import { computed, defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'FlagComponent',
    props: {
        iso: { type: String, required: true },
        title: { type: String, required: true },
    },
    setup(props) {
        // Computed
        const flagIconClass = computed(() => `fi-${props.iso.toLowerCase()}`);
        return { flagIconClass };
    },
});
