import { Song } from '@ddradar/core';
import { apiPrefix } from '~/api';
export function shortenSeriesName(series) {
    return series.replace(/^(DDR |DanceDanceRevolution )\(?([^)]+)\)?$/, '$2');
}
export function getChartTitle({ playStyle, difficulty, level, }) {
    const shortPlayStyle = `${Song.playStyleMap.get(playStyle)[0]}P`;
    const difficultyName = Song.difficultyMap.get(difficulty);
    return `${shortPlayStyle}-${difficultyName} (${level})`;
}
export function getDisplayedBPM({ minBPM, maxBPM, }) {
    if (!minBPM || !maxBPM)
        return '???';
    if (minBPM === maxBPM)
        return `${minBPM}`;
    return `${minBPM}-${maxBPM}`;
}
/**
 * Call "Get Song Information" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/songs__id/
 */
export function getSongInfo($http, id) {
    return $http.$get(`${apiPrefix}/songs/${id}`);
}
/**
 * Call "Search Song" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/songs--get/
 */
export function searchSong($http, nameIndex, seriesIndex) {
    const searchParams = new URLSearchParams();
    if (nameIndex !== undefined)
        searchParams.append('name', `${nameIndex}`);
    if (seriesIndex !== undefined)
        searchParams.append('series', `${seriesIndex}`);
    return $http.$get(`${apiPrefix}/songs`, { searchParams });
}
/**
 * Call "Search Charts" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/charts__style__level/
 */
export function searchCharts($http, playStyle, level) {
    return $http.$get(`${apiPrefix}/charts/${playStyle}/${level}`);
}
/**
 * Call "Post Song Information" API. (Admin only)
 * @see https://github.com/ddradar/ddradar/tree/master/api/songs--post/
 */
export function postSongInfo($http, body) {
    return $http.$post(`${apiPrefix}/songs`, body);
}
