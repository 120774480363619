import { calcMyGrooveRadar } from '../score';
import { hasIntegerProperty } from '../typeUtils';
import { isAreaUser } from './users';
const clearLamps = new Map([
    [0, 'Failed'],
    [1, 'Assisted Clear'],
    [2, 'Clear'],
    [3, 'Life 4'],
    [4, 'Full Combo'],
    [5, 'Great Full Combo'],
    [6, 'Perfect Full Combo'],
    [7, 'Marvelous Full Combo'],
]);
export const clearLampMap = clearLamps;
const danceLevels = [
    'E',
    'D',
    'D+',
    'C-',
    'C',
    'C+',
    'B-',
    'B',
    'B+',
    'A-',
    'A',
    'A+',
    'AA-',
    'AA',
    'AA+',
    'AAA',
];
export const danceLevelSet = new Set(danceLevels);
/**
 * Create {@link ScoreSchema} from song, chart, user and score.
 * @param song Song or Course data (partial)
 * @param chart Chart data (partial)
 * @param user User info (if area score, use mock user)
 * @param score Score data
 */
export function createScoreSchema(song, chart, user, score) {
    const scoreSchema = {
        userId: user.id,
        userName: user.name,
        isPublic: user.isPublic,
        songId: song.id,
        songName: song.name,
        playStyle: chart.playStyle,
        difficulty: chart.difficulty,
        level: chart.level,
        score: score.score,
        clearLamp: score.clearLamp,
        rank: score.rank,
    };
    if (score.exScore)
        scoreSchema.exScore = score.exScore;
    if (score.maxCombo)
        scoreSchema.maxCombo = score.maxCombo;
    if (song.deleted)
        scoreSchema.deleted = true;
    if (!isAreaUser(user) && isSongChart(chart)) {
        scoreSchema.radar = calcMyGrooveRadar(chart, score);
    }
    return scoreSchema;
    function isSongChart(chart) {
        return hasIntegerProperty(chart, 'stream');
    }
}
