var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'nuxt-property-decorator';
import { searchSong } from '~/api/song';
let SearchBoxComponent = class SearchBoxComponent extends Vue {
    constructor() {
        super(...arguments);
        this.songList = [];
        this.term = '';
    }
    get filtered() {
        return this.term.trim()
            ? this.songList.filter(s => s.name.toUpperCase().includes(this.term.toUpperCase()) ||
                s.nameKana.includes(this.term.toUpperCase()) ||
                s.artist.toUpperCase().includes(this.term.toUpperCase()))
            : [];
    }
    async fetch() {
        try {
            const songs = await searchSong(this.$http);
            this.songList = songs.map(s => ({
                id: s.id,
                name: s.name,
                nameKana: s.nameKana,
                artist: s.artist,
            }));
        }
        catch (_a) { }
    }
    move({ id }) {
        this.$router.push(`/songs/${id}`);
    }
};
SearchBoxComponent = __decorate([
    Component({ fetchOnServer: false })
], SearchBoxComponent);
export default SearchBoxComponent;
