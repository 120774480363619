var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Song } from '@ddradar/core';
import { Component, Vue } from 'nuxt-property-decorator';
import { shortenSeriesName } from '~/api/song';
import Flag from '~/components/pages/Flag.vue';
import SearchBox from '~/components/pages/SearchBox.vue';
let DefaultLayout = class DefaultLayout extends Vue {
    get userPage() {
        var _a;
        return `/users/${(_a = this.$accessor.user) === null || _a === void 0 ? void 0 : _a.id}`;
    }
    get menuList() {
        const seriesList = [...Song.seriesSet];
        return [
            {
                label: this.$t('menu.single'),
                items: [...Array(19).keys()].map(i => ({
                    name: `LEVEL ${i + 1}`,
                    to: `/single/${i + 1}`,
                })),
            },
            {
                label: this.$t('menu.double'),
                items: [...Array(19).keys()].map(i => ({
                    name: `LEVEL ${i + 1}`,
                    to: `/double/${i + 1}`,
                })),
            },
            {
                label: this.$t('menu.series'),
                items: seriesList
                    .map((name, i) => ({
                    name,
                    to: `/series/${i}`,
                }))
                    .reverse(),
            },
            {
                label: this.$t('menu.course'),
                items: [16, 17]
                    .map(i => [
                    {
                        name: this.$t('menu.nonstop', {
                            series: shortenSeriesName(seriesList[i]),
                        }),
                        to: `/nonstop/${i}`,
                    },
                    {
                        name: this.$t('menu.grade', {
                            series: shortenSeriesName(seriesList[i]),
                        }),
                        to: `/grade/${i}`,
                    },
                ])
                    .flat(),
            },
        ];
    }
    get selectedLocale() {
        return this.$i18n.locales.find((i) => typeof i === 'object' && i.code === this.$i18n.locale);
    }
    get availableLocales() {
        return this.$i18n.locales.filter((i) => typeof i === 'object' && i.code !== this.$i18n.locale);
    }
    /** Get Login user info */
    async fetch() {
        await this.$accessor.fetchUser();
        if (this.$accessor.auth && !this.$accessor.isLoggedIn)
            this.$router.push('/profile');
    }
};
DefaultLayout = __decorate([
    Component({ components: { Flag, SearchBox }, fetchOnServer: false })
], DefaultLayout);
export default DefaultLayout;
