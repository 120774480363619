import { ApplicationInsights } from '@microsoft/applicationinsights-web';
const appInsightsPlugin = ({ $config }) => {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: $config.instrumentationKey,
            enableAutoRouteTracking: true,
        },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
};
export default appInsightsPlugin;
