import { actionTree, getAccessorType, mutationTree } from 'typed-vuex';
import { getClientPrincipal } from '~/api/auth';
import { getCurrentUser, postUserInfo } from '~/api/user';
export const state = () => ({
    auth: null,
    user: null,
});
export const getters = {
    name: (state) => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.name; },
    isLoggedIn: (state) => !!state.user,
    isAdmin: (state) => { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userRoles.includes('administrator')) !== null && _b !== void 0 ? _b : false; },
};
export const mutations = mutationTree(state, {
    setAuth(state, auth) {
        state.auth = auth;
    },
    setUser(state, user) {
        state.user = user;
    },
});
export const actions = actionTree({ state, getters, mutations }, {
    async fetchUser({ commit }) {
        const clientPrincipal = await getClientPrincipal(this.$http);
        commit('setAuth', clientPrincipal);
        if (!clientPrincipal) {
            // Not login
            commit('setUser', null);
            return;
        }
        try {
            const user = await getCurrentUser(this.$http);
            commit('setUser', user);
        }
        catch (_a) {
            commit('setUser', null);
        }
    },
    logout({ commit }) {
        commit('setAuth', null);
        commit('setUser', null);
    },
    async saveUser({ commit }, user) {
        const response = await postUserInfo(this.$http, user);
        commit('setUser', response);
    },
});
export const accessorType = getAccessorType({
    state,
    getters,
    mutations,
    actions,
});
