import { hasIntegerProperty, hasProperty, hasStringProperty, } from '../typeUtils';
/** Returns `id` is valid {@link SongSchema.id} or not. */
export function isValidId(id) {
    return /^[01689bdiloqDIOPQ]{32}$/.test(id);
}
/** Type assertion for {@link SongSchema} */
export function isSongSchema(obj) {
    return (hasStringProperty(obj, 'id', 'name', 'nameKana', 'artist', 'series') &&
        isValidId(obj.id) &&
        /^([A-Z0-9 .ぁ-んー]*)$/.test(obj.nameKana) &&
        seriesSet.has(obj.series) &&
        hasIntegerProperty(obj, 'nameIndex') &&
        obj.nameIndex >= 0 &&
        obj.nameIndex <= 36 &&
        (hasIntegerProperty(obj, 'minBPM', 'maxBPM') ||
            (hasProperty(obj, 'minBPM', 'maxBPM') &&
                obj.minBPM === null &&
                obj.maxBPM === null)) &&
        hasProperty(obj, 'charts') &&
        Array.isArray(obj.charts) &&
        obj.charts.every(c => isStepChartSchema(c)));
    function isStepChartSchema(obj) {
        return (hasIntegerProperty(obj, 'playStyle', 'difficulty', 'level', 'notes', 'freezeArrow', 'shockArrow', 'stream', 'voltage', 'air', 'freeze', 'chaos') &&
            (obj.playStyle === 1 || obj.playStyle === 2) &&
            obj.difficulty >= 0 &&
            obj.difficulty <= 4 &&
            obj.level >= 1 &&
            obj.level <= 20);
    }
}
const series = new Set([
    'DDR 1st',
    'DDR 2ndMIX',
    'DDR 3rdMIX',
    'DDR 4thMIX',
    'DDR 5thMIX',
    'DDRMAX',
    'DDRMAX2',
    'DDR EXTREME',
    'DDR SuperNOVA',
    'DDR SuperNOVA2',
    'DDR X',
    'DDR X2',
    'DDR X3 VS 2ndMIX',
    'DanceDanceRevolution (2013)',
    'DanceDanceRevolution (2014)',
    'DanceDanceRevolution A',
    'DanceDanceRevolution A20',
    'DanceDanceRevolution A20 PLUS',
    'DanceDanceRevolution A3',
]);
export const seriesSet = series;
const nameIndexes = new Map([
    [0, 'あ'],
    [1, 'か'],
    [2, 'さ'],
    [3, 'た'],
    [4, 'な'],
    [5, 'は'],
    [6, 'ま'],
    [7, 'や'],
    [8, 'ら'],
    [9, 'わ'],
    [10, 'A'],
    [11, 'B'],
    [12, 'C'],
    [13, 'D'],
    [14, 'E'],
    [15, 'F'],
    [16, 'G'],
    [17, 'H'],
    [18, 'I'],
    [19, 'J'],
    [20, 'K'],
    [21, 'L'],
    [22, 'M'],
    [23, 'N'],
    [24, 'O'],
    [25, 'P'],
    [26, 'Q'],
    [27, 'R'],
    [28, 'S'],
    [29, 'T'],
    [30, 'U'],
    [31, 'V'],
    [32, 'W'],
    [33, 'X'],
    [34, 'Y'],
    [35, 'Z'],
    [36, '数字・記号'],
]);
export const nameIndexMap = nameIndexes;
/**
 * Get {@link NameIndex} from Furigana.
 * @param nameKana Furigana
 */
export function getNameIndex(nameKana) {
    const regExps = new Map([
        [/^[ぁ-お]/, 0],
        [/^[か-ご]/, 1],
        [/^[さ-ぞ]/, 2],
        [/^[た-ど]/, 3],
        [/^[な-の]/, 4],
        [/^[は-ぽ]/, 5],
        [/^[ま-も]/, 6],
        [/^[ゃ-よ]/, 7],
        [/^[ら-ろ]/, 8],
        [/^[ゎ-ん]/, 9],
        [/^[aA]/, 10],
        [/^[bB]/, 11],
        [/^[cC]/, 12],
        [/^[dD]/, 13],
        [/^[eE]/, 14],
        [/^[fF]/, 15],
        [/^[gG]/, 16],
        [/^[hH]/, 17],
        [/^[iI]/, 18],
        [/^[jJ]/, 19],
        [/^[kK]/, 20],
        [/^[lL]/, 21],
        [/^[mM]/, 22],
        [/^[nN]/, 23],
        [/^[oO]/, 24],
        [/^[pP]/, 25],
        [/^[qQ]/, 26],
        [/^[rR]/, 27],
        [/^[sS]/, 28],
        [/^[tT]/, 29],
        [/^[uU]/, 30],
        [/^[vV]/, 31],
        [/^[wW]/, 32],
        [/^[xX]/, 33],
        [/^[yY]/, 34],
        [/^[zZ]/, 35],
    ]);
    for (const map of regExps) {
        if (map[0].test(nameKana))
            return map[1];
    }
    return 36;
}
const playStyles = new Map([
    [1, 'SINGLE'],
    [2, 'DOUBLE'],
]);
export const playStyleMap = playStyles;
const difficulties = new Map([
    [0, 'BEGINNER'],
    [1, 'BASIC'],
    [2, 'DIFFICULT'],
    [3, 'EXPERT'],
    [4, 'CHALLENGE'],
]);
export const difficultyMap = difficulties;
