export function hasProperty(x, ...names) {
    return x instanceof Object && names.every(n => n in x);
}
export function hasStringProperty(x, ...names) {
    return hasProperty(x, ...names) && names.every(n => typeof x[n] === 'string');
}
export function hasIntegerProperty(x, ...names) {
    return (hasProperty(x, ...names) &&
        names.every(n => typeof x[n] === 'number' && Number.isInteger(x[n])));
}
